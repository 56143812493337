import styled from "styled-components"

import bgHeaderTitle from "../../../static/bg-card-vantagens.webp"

export const Content = styled.div`
  margin-bottom: 3rem;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;

  h2 {
    margin: 2rem 0;
    font-family: "Gosha", sans-serif;
  }
  ol {
    padding-left: 2rem;
  }
  p {
    margin-bottom: 2rem;
  }

  a {
    font-weight: bold;
    color: var(--blue-dark);
  }
`

export const HeaderTitle = styled.div`
  width: 100%;
  padding: 4rem 0;
  margin-bottom: 2rem;
  background: var(--blue-dark) url(${bgHeaderTitle}) center center/ cover;
  background-attachment: fixed;

  h1 {
    text-align: left;
    padding-left: 2rem;
    color: #ffffff;
    font-family: "Gosha", sans-serif;
  }
`
